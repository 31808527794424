import React from "react";
import styles from "./ContactSec.module.css";
// import a from "next/a";
import { BsTelephone } from "react-icons/bs";
import { GoMail } from "react-icons/go";

const ContactSec = () => {
  return (
    <section className={styles.contactSec}>
      <div className="container">
        <div className="row">
          <div className={`col-lg-6 ${styles.new_col}`}>
            <div className={styles.ContactSec_content}>
              <h3>
                Contact a Travel <span>Researcher</span>
              </h3>
              <p>We always aim to reply within 24 hours.</p>
            </div>
          </div>
          <div className={`col-lg-6 ${styles.new_col}`}>
            <div className={styles.contact_circle_main}>
              <a href="/">
                <div className={styles.contact_circle}>
                  <div className={styles.contact_circle_content}>
                    <span>
                      <BsTelephone size={25} color="#fff" fontWeight={400} />
                    </span>

                    <h5>Call Us</h5>
                    <p>+123456789</p>
                  </div>
                </div>
              </a>

              <a href="/">
                <div className={styles.contact_circle}>
                  <div className={styles.contact_circle_content}>
                    <span>
                      <GoMail size={25} color="#fff" fontWeight={400} />
                    </span>

                    <h5>Send us an enquiry</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSec;
