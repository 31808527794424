import React from "react";
import OwlCarousel from "react-owl-carousel";
import styles from "./TrustPilotSlider.module.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import trustPilot from "../../assets/images/trustpilot.png";
import trustPilot_LOGO from "../../assets/images/trustpilot_logo.svg";

const TrustPilotSlider = () => {
  const Responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  };

  return (
    <section className={styles.trust_pilot_slider}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <div className={styles.trust_pilot_excellent}>
              <h4>Excellent</h4>
              <img src={trustPilot} width={500} height={500} alt="trustpilot" />

              <div className={styles.trust_pilot_reviews}>
                <p>
                  Based on <a href="/">66 reviews</a>
                </p>
                <img
                  src={trustPilot_LOGO}
                  alt="trustpilot_Logo"
                  width={500}
                  height={500}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className={styles.swiper_container}>
              <OwlCarousel
                className="owl-theme trustSlider"
                responsive={Responsive}
                nav={true}
                dots={false}
              >
                <div className="item">
                  <div>
                    <div className={styles.slider_sec}>
                      <div className={styles.slider_header}>
                        <img
                          src={trustPilot}
                          width={500}
                          height={500}
                          alt="trustpilot"
                        />

                        <div className={styles.verifiedDiv}>
                          <BsFillCheckCircleFill color="#6c6c85" size={14} />
                          <span> Verified</span>
                        </div>
                      </div>

                      <div className={styles.slider_body}>
                        <a href="/">
                          <h5>The Perfect First-Class, First-Time Safari</h5>
                          <p>
                            The communication and logistical planning made this
                            vacation effortless for us...
                          </p>

                          <p className={styles.nameAndData}>
                            <span>Daryl & Susan Martens,</span> 18 October
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div className={styles.slider_sec}>
                      <div className={styles.slider_header}>
                        <img
                          src={trustPilot}
                          width={500}
                          height={500}
                          alt="trustpilot"
                        />

                        <div className={styles.verifiedDiv}>
                          <BsFillCheckCircleFill color="#6c6c85" size={14} />
                          <span> Verified</span>
                        </div>
                      </div>

                      <div className={styles.slider_body}>
                        <a href="/">
                          <h5>The Perfect First-Class, First-Time Safari</h5>
                          <p>
                            The communication and logistical planning made this
                            vacation effortless for us...
                          </p>

                          <p className={styles.nameAndData}>
                            <span>Daryl & Susan Martens,</span> 18 October
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div className={styles.slider_sec}>
                      <div className={styles.slider_header}>
                        <img
                          src={trustPilot}
                          width={500}
                          height={500}
                          alt="trustpilot"
                        />

                        <div className={styles.verifiedDiv}>
                          <BsFillCheckCircleFill color="#6c6c85" size={14} />
                          <span> Verified</span>
                        </div>
                      </div>

                      <div className={styles.slider_body}>
                        <a href="/">
                          <h5>The Perfect First-Class, First-Time Safari</h5>
                          <p>
                            The communication and logistical planning made this
                            vacation effortless for us...
                          </p>

                          <p className={styles.nameAndData}>
                            <span>Daryl & Susan Martens,</span> 18 October
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div className={styles.slider_sec}>
                      <div className={styles.slider_header}>
                        <img
                          src={trustPilot}
                          width={500}
                          height={500}
                          alt="trustpilot"
                        />

                        <div className={styles.verifiedDiv}>
                          <BsFillCheckCircleFill color="#6c6c85" size={14} />
                          <span> Verified</span>
                        </div>
                      </div>

                      <div className={styles.slider_body}>
                        <a href="/">
                          <h5>The Perfect First-Class, First-Time Safari</h5>
                          <p>
                            The communication and logistical planning made this
                            vacation effortless for us...
                          </p>

                          <p className={styles.nameAndData}>
                            <span>Daryl & Susan Martens,</span> 18 October
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div>
                    <div className={styles.slider_sec}>
                      <div className={styles.slider_header}>
                        <img
                          src={trustPilot}
                          width={500}
                          height={500}
                          alt="trustpilot"
                        />

                        <div className={styles.verifiedDiv}>
                          <BsFillCheckCircleFill color="#6c6c85" size={14} />
                          <span> Verified</span>
                        </div>
                      </div>

                      <div className={styles.slider_body}>
                        <a href="/">
                          <h5>The Perfect First-Class, First-Time Safari</h5>
                          <p>
                            The communication and logistical planning made this
                            vacation effortless for us...
                          </p>

                          <p className={styles.nameAndData}>
                            <span>Daryl & Susan Martens,</span> 18 October
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustPilotSlider;
