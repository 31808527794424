import React from "react";
import styles from "./WhyNiarra.module.css";
// import map from "../../assets/images/map.svg";
// import smoke from "../../assets/images/smoke-man.jpg";
// import elephent from "../../assets/images/elephent.jpg";
// import people from "../../assets/images/people.svg";
// import world from "../../assets/images/world.svg";
import wheelCircle from "../../assets/images/wheel-circle.gif";

const WhyNiarra = () => {
  return (
    <section className={styles.WhyNiarraSec}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.wheelCircle_div}>
              <img src={wheelCircle} alt="wheelCircle" />

              <ul>
                <li>
                  <h3>Primary Due Diligence:</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem veritatis ipsum in voluptas, numquam est aperiam
                    facilis nobis eligendi culpa alias dolor atque deserunt
                    laboriosam!
                  </p>
                </li>

                <li>
                  <h3>Strategic Criteria Evaluation:</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem veritatis ipsum in voluptas, numquam est aperiam
                    facilis nobis eligendi culpa alias dolor atque deserunt
                    laboriosam!
                  </p>
                </li>

                <li>
                  <h3>Elite Selection Showcase:</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem veritatis ipsum in voluptas, numquam est aperiam
                    facilis nobis eligendi culpa alias dolor atque deserunt
                    laboriosam!
                  </p>
                </li>

                <li style={{ marginLeft: "-90pxn" }}>
                  <h3>Exploration Level:</h3>
                  <p>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem veritatis ipsum in voluptas, numquam est aperiam
                    facilis nobis eligendi culpa alias dolor atque deserunt
                    laboriosam!
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyNiarra;
