import React, { useState } from "react";
import styles from "./CountrySlider.module.css";
import { MdLocationPin } from "react-icons/md";

import florida from "../../assets/images/Florida-01.svg";
import Newyork from "../../assets/images/Newyork-01.svg";
import TexasImg from "../../assets/images/Texas-01.svg";
import WashingtonImg from "../../assets/images/Washignton-01.svg";
import newJersey from "../../assets/images/New jersey-01.svg";

import { gsap } from "gsap";
import useMouse from "@react-hook/mouse-position";
// import { motion, useTransform } from "framer-motion";

import Slider from "react-slick";

const CountrySlider = () => {
  const ref = React.useRef(null);

  const [activeSlideIndex, setActiveSlideIndex] = React.useState(1);

  // React.useEffect(() => {
  //   const image = new Image();
  //   image.src = activeSlideIndex;
  //   image.onload = () => {
  //     document.querySelector(
  //       ".bgImagee"
  //     ).style.backgroundImage = `url(${activeSlideIndex})`;
  //   };
  // }, [activeSlideIndex]);

  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  let mouseXPosition = mouse.clientX;
  let mouseYPosition = mouse.clientX;

  if (mouse.x !== null) {
    mouseXPosition = mouse.clientX;
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.clientY;
  }

  const settings = {
    className: "slider variable-width",
    lazyLoad: true,
    infinite: false,
    centerMode: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    beforeChange: (next, currentSlide) => {
      if (currentSlide === 0) {
        setActiveSlideIndex(1);
      } else if (currentSlide === 1) {
        setActiveSlideIndex(2);
      } else if (currentSlide === 2) {
        setActiveSlideIndex(3);
      } else if (currentSlide === 3) {
        setActiveSlideIndex(4);
      } else if (currentSlide === 4) {
        setActiveSlideIndex(5);
      }
    },
  };
  React.useEffect(() => {
    if (mouse.clientX !== null) {
      gsap.set("#ball", { xPercent: -50, yPercent: -50 });
      let targets = gsap.utils.toArray("#ball");
      gsap.to(targets, {
        duration: 0.9,
        x: mouseXPosition,
        y: mouseYPosition,
        ease: "power1.out",
        overwrite: "auto",
        stagger: 0.01,
      });
    }
  }, [mouse]);

  React.useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector("#nnewTxt"),
      {
        opacity: 0,
      },
      {
        duration: 3,
        opacity: 1,
      }
    );
  }, [activeSlideIndex]);

  return (
    <div style={{ position: "relative", height: "100vh", zIndex: 1 }} ref={ref}>
      {mouse.clientX !== null && (
        <div
          id="ball"
          style={{
            position: "absolute",
            display: "flex",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",

            backgroundColor: "transparent",
          }} className="customCursor">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0,0,200,200"
            width={"200"}
            height="200"
            className="clockwise">
            <g
              fill="none"
              stroke="#ffffff"
              strokeWidth="1"
              strokeDasharray="2.5">
              <circle cx="100" cy="100" r="30" />
              <circle
                strokeWidth="0"
                strokeDasharray="0"
                cx="100"
                cy="100"
                r="15"
                fill="rgb(212 212 212)"
              />
            </g>
          </svg>
        </div>
      )}

      <div
        style={{ zIndex: -1 }}
        className={
          activeSlideIndex == 1
            ? styles.bgImg1
            : activeSlideIndex == 2
            ? styles.bgImg2
            : activeSlideIndex == 3
            ? styles.bgImg3
            : activeSlideIndex == 4
            ? styles.bgImg4
            : activeSlideIndex == 5
            ? styles.bgImg5
            : styles.bgImg1
        }></div>
      <div style={{ height: "100%", width: "90%" }}>
        <Slider className="slidersMap" {...settings}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1050,
            }}>
            <div className={styles.smallMaps}>
              <img src={florida} width={500} height={500} alt="Florida-01" />
              <h3>I want to explore</h3>
              <h2>Florida</h2>

              <div className="mt-3 mb-3">
                <a href="#">Discover more</a>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1050,
            }}>
            <div className={`${styles.smallMaps} ${styles.smallMaps3}`}>
              <img src={newJersey} width={500} height={500} alt="Florida-01" />

              <h3>I want to explore</h3>
              <h2>New Jersey</h2>

              <div className="mt-3 mb-3">
                <a href="#">Discover more</a>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1050,
            }}>
            <div className={styles.smallMaps}>
              <img src={Newyork} width={500} height={500} alt="Florida-01" />

              <h3>I want to explore</h3>
              <h2>New York</h2>

              <div className="mt-3 mb-3">
                <a href="#">Discover more</a>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1050,
            }}>
            <div className={styles.smallMaps}>
              <img src={TexasImg} width={500} height={500} alt="Florida-01" />

              <h3>I want to explore</h3>
              <h2>Texas</h2>

              <div className="mt-3 mb-3">
                <a href="#">Discover more</a>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 1050,
            }}>
            <div className={styles.smallMaps}>
              <img
                src={WashingtonImg}
                width={500}
                height={500}
                alt="Florida-01"
              />

              <h3>I want to explore</h3>
              <h2>Washington</h2>

              <div className="mt-3 mb-3">
                <a href="#">Discover more</a>
              </div>
            </div>
          </div>
        </Slider>
        <span className={styles.location}>
          <MdLocationPin
            style={{ marginBottom: 16, marginRight: 5 }}
            size={20}
          />
          <p id="nnewTxt">
            Lorem ipsum,{" "}
            {activeSlideIndex == 1
              ? "Florida"
              : activeSlideIndex == 2
              ? "New Jersey"
              : activeSlideIndex == 3
              ? "New York"
              : activeSlideIndex == 4
              ? "Texas"
              : "Washington"}
          </p>
        </span>
      </div>
    </div>
  );
};

export default CountrySlider;
