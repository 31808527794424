import React, { useRef, useEffect } from "react";
import styles from "./Receiveinspiration.module.css";

import footerClouds from "../../assets/images/footer-clouds.png";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Receiveinspiration = () => {
  const ref = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const element = ref.current;
    gsap.fromTo(
      element.querySelector("#firstmackbookimg"),
      {
        y: -180,
      },
      {
        scrollTrigger: {
          trigger: element.querySelector(".WeAreTeam"),
          start: "top center",
          end: "center center",
          scrub: true,
        },
        y: -80,
        duration: 3,
        ease: "none",
      }
    );
    gsap.fromTo(
      element.querySelector("#textinner"),
      {
        opacity: 0,
        scale: 1.3,
      },
      {
        scrollTrigger: {
          trigger: element.querySelector(".textView"),
          start: "top bottom",
          end: "top center",
          scrub: true,
        },
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: "none",
      }
    );
  }, []);
  return (
    <section ref={ref} className={`${styles.block_newsletter__background}`}>
      <div className="WeAreTeam">
        <img
          id="firstmackbookimg"
          src={footerClouds}
          className={styles.cloudImg}
          alt="elephent"
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "50px",
          width: "100%",
          left: 0,
          zIndex: 1,
        }}>
        <div className={`container ${styles.recieve_form_container}`}>
          <div className="row">
            <div className="textView col-lg-12">
              <div id="textinner" className={`${styles.receive_form}`}>
                <h3>THE WORLD IS AT YOUR FEET</h3>
                <h2>
                  Receive <span>inspiration</span> in <div>your inbox</div>
                </h2>
              </div>
              <div style={styles.receive_form_div}>
                <form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-5">
                        <input
                          type="text"
                          className={styles.inputs}
                          placeholder="Enter your first name"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-5">
                        <input
                          type="text"
                          className={styles.inputs}
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="mb-5">
                        <input
                          type="email"
                          className={styles.inputs}
                          placeholder="Enter your e-mail address"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div
                        className={`form-group mb-5 form-check ${styles.checkText}`}>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1">
                          I&apos;m happy to receive emails from Intentional
                          Capitalist *
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="mt-2 text-center">
                        <button className={styles.subscribe_btn}>
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ParallaxLayer speed={0.01}></ParallaxLayer> */}
    </section>
  );
};

export default Receiveinspiration;
