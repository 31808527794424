import React from "react";
import { BiLogoFacebook } from "react-icons/bi";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import styles from "./Footer.module.css";
import trustpilotImg from "../../assets/images/trustpilot_logo.svg";
import longRunImg from "../../assets/images/long_run.png";
import Footer_Logo from "../../assets/images/mainLogo.png";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
            <div className={styles.footerDiv01}>
              <p>DESTINATIONS</p>

              <ul>
                <li>
                  <a href="/">Florida</a>
                </li>
                <li>
                  <a href="/">New Jersey</a>
                </li>
                <li>
                  <a href="/">New York</a>
                </li>
                <li>
                  <a href="/">Texas</a>
                </li>
                <li>
                  <a href="/">Washington</a>
                </li>
              </ul>
            </div>
          </div>
          <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
            <div className={styles.footerDiv02}>
              <div className={styles.footerDiv02_inner}>
                <p>CALL US</p>
                <a href="/">+123456789</a>
              </div>

              <div
                className={`${styles.footerDiv02_inner} ${styles.footerDiv02_inner2}`}
              >
                <p>E-MAIL US</p>
                <a href="/">abc@gmail.com</a>
              </div>
            </div>
          </div>
          <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
            <div>
              <div className={styles.footer_review_div}>
                <a href="/">
                  <span>Review us on</span>
                  <img
                    src={trustpilotImg}
                    width={500}
                    height={500}
                    alt="trustpilot"
                  />
                </a>
              </div>

              <div className={styles.social_linksDiv}>
                <a href="/">
                  <BiLogoFacebook fontSize={20} color="#b1b886" />
                </a>

                <a href="/">
                  <BsInstagram fontSize={20} color="#b1b886" />
                </a>

                <a href="/">
                  <BsLinkedin fontSize={20} color="#b1b886" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className={styles.terms_footer}>
              <div className="row">
                <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
                  <div className={styles.terms_linkDiv1}>
                    <img src={Footer_Logo} alt="Footer_Logo" />
                  </div>
                </div>
                <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
                  <div className={styles.terms_linkDiv}>
                    <ul>
                      <li>
                        <a href="/">Terms</a>
                      </li>

                      <li>
                        <a href="/">Cookie Policy</a>
                      </li>

                      <li>
                        <a href="/">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={`col-lg-4 ${styles.newCol_lg_4}`}>
                  <div className={styles.longRunDiv}>
                    <div className="text-center">
                      <img
                        src={longRunImg}
                        width={500}
                        height={500}
                        alt="long_run"
                      />
                    </div>
                    <p>
                      A proud Travel Partner of
                      <a href="/"> The Long Run</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
