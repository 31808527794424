"use client";
import React from "react";
// header module css
import styles from "./Header.module.css";
import image1 from "../../assets/images/mainLogo.png";
import image12 from "../../assets/images/btn-image.svg";

// bootstrap imports
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

const Header = () => {
  return (
    <>
      <header className={styles.main_header}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Navbar collapseOnSelect expand="lg">
                <Container>
                  <a className="navbar-brand" href={"/"}>
                    <img src={image1} className={styles.logo} alt="logo" />
                  </a>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                      <NavDropdown
                        title="DESTINATIONS"
                        className={styles.nav_links}
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item href="#">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown
                        title="TRAVEL STYLES"
                        className={styles.nav_links}
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item href="#">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>

                      <NavDropdown
                        title="ABOUT US"
                        className={styles.nav_links}
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item href="#">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link href="#" className={styles.nav_links}>
                        Stories
                      </Nav.Link>
                    </Nav>
                    <Nav>
                      <NavDropdown
                        title="$ USD"
                        className={styles.nav_links}
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item href="#">€ EUR</NavDropdown.Item>
                        <NavDropdown.Item href="#">£ GBP</NavDropdown.Item>
                        <NavDropdown.Item href="#">$ AUD</NavDropdown.Item>
                        <NavDropdown.Item href="#">$ CAD</NavDropdown.Item>
                      </NavDropdown>
                      <Nav.Link
                        className={styles.green_btn}
                        eventKey={2}
                        href="#"
                      >
                        <img
                          src={image12}
                          width={500}
                          height={500}
                          className={styles.btn_image}
                          alt="btn-image"
                        />
                        Enquire
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
