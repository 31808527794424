import React from "react";
import styles from "./AwardsSec.module.css";
import Image1 from "../../assets/images/traveler.png";
import Image2 from "../../assets/images/robb-report.png";
import Image3 from "../../assets/images/bloomberg.png";
import Image4 from "../../assets/images/forbes.png";
import Image5 from "../../assets/images/afar.png";

const AwardsSec = () => {
  return (
    <section className={styles.awards_sec}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.awards_content}>
              <p>AS SEEN IN</p>

              <div className={styles.awards_awards_img}>
                <div>
                  <a href="/">
                    <img src={Image1} width={500} height={500} alt="" />
                  </a>
                </div>

                <div>
                  <a href="/">
                    <img src={Image2} width={500} height={500} alt="" />
                  </a>
                </div>

                <div>
                  <a href="/">
                    <img src={Image3} width={500} height={500} alt="" />
                  </a>
                </div>

                <div>
                  <a href="/">
                    <img src={Image4} width={500} height={500} alt="" />
                  </a>
                </div>

                <div>
                  <a href="/">
                    <img src={Image5} width={500} height={500} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AwardsSec;
