
import './App.css';
import React, { useEffect } from "react";
import Header from './components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './components/Banner/Banner';
import WhyNiarra from './components/WhyNiarra/WhyNiarra';
import CountrySlider from './components/CountrySlider/CountrySlider';
import TrustPilotSlider from './components/TrustPilotSlider/TrustPilotSlider';
import Receiveinspiration from './components/Receiveinspiration/Receiveinspiration';
import ContactSec from './components/ContactSec/ContactSec';
import AwardsSec from './components/AwardsSec/AwardsSec';
import Footer from './components/Footer/Footer';
// import { gsap } from "gsap";
// import { ScrollSmoother, ScrollTrigger } from "gsap-trial/all";

const App = () => {
  // const ref = React.useRef();
  // gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  // useEffect(() => {
  //   ScrollSmoother.create({
  //     content: ".Viewport",
  //     smooth: 2,
  //   })
  // }, []);


  return (
    <>
      <Header />
      <Banner />
      <WhyNiarra />
      <CountrySlider />
      <TrustPilotSlider />
      <Receiveinspiration />
      <ContactSec />
      <AwardsSec />
      <Footer />
    </>
  );
}

export default App;
