import React, { useEffect, useRef } from "react";
import styles from "./Banner.module.css";
import Depositphotos from "../../assets/images/Depositphotos.webp";
import video from "../../assets/images/video-btn-img.jpg";
// import AnimatedCircleMain from "../../utils/AnimatedCircleMain";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap-trial/all";

const Banner = () => {
  const ref = useRef();
  useEffect(() => {
    const element = ref.current;
    gsap.fromTo(
      element.querySelector("#textinner"),
      {
        opacity: 0,
      },
      {
        opacity: 1,
        delay: 0.6,
        ease: "none",
      }
    );
    gsap.fromTo(
      element.querySelector("#textinner2"),
      {
        letterSpacing: 14,
        opacity: 0,
      },
      {
        opacity: 1,
        letterSpacing: 1,
        duration: 0.5,
        delay: 0.8,
        ease: "none",
      }
    );
  }, []);
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = event.clientX / window.innerWidth - 0.5;
      const y = event.clientY / window.innerHeight - 0.5;
      gsap.to(".photo", {
        rotationY: 10 * x,
        rotationX: 10 * y,
        ease: "power1.easeOut",
        transformPerspective: 700,
        transformOrigin: "center",
      });
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <section ref={ref} className={styles.banner_main}>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          transform: `scale(1.2)`,
          zIndex: -1,
          backgroundColor: "#000",
        }}
        className="photo">
        <img
          src={Depositphotos}
          alt="Banner Background"
          layout="responsive"
          // Set the height of your image
          className={styles.bannerImg}
        />
      </div>
      <div className={styles.container}>
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.banner_content_div}>
              <h3 id="textinner">CONNECTING YOUR JOURNEY WITH PURPOSE</h3>
              <h2 id="textinner2">
                <span>Unforgettable travel</span>

                <span>experiences with</span>
                <span>
                  a <em className={styles.italic_key}>positive</em> impact
                </span>
              </h2>
            </div>

            <div className={styles.video_btn_div}>
              <button>
                <div>
                  <img src={video} alt="video-btn-img" />
                </div>
                <span>Open video</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
